.title-1-container {
   display: flex;
}
.title-1 {
    margin-left: 5px;
    margin-top: 50px;
    font-family: cursive;
    color: #007466;
    letter-spacing: 1.1px;
}
.logo1 {
    margin-top: 5px;
    width: 120px;
    height: 80px;
}
.logo2 {
    margin-top: 5px;
    width: 100px;
    height: 60px;
    margin-left: 20px;
}
.title-2-container {
    display: flex;
}
.title-2 {
    font-family: cursive;
    margin-left: 200px;
    margin-top: 2px;
    color: #bd8c00;
}

.model-header-page {
    display: flex;
    background-color: white;
    box-shadow: 2px 2px rgb(166, 166, 166);
    justify-content: space-between;
    position: fixed;
    width: 100%;
    /* box-sizing: border-box; */
    top: 0px;
}
/* .Header-Container {
   margin-left: 20px;
} */
.tags { 
    display: flex;
    margin-right: 10px;
    /* align-items: center; */
    align-items:end;
    padding: 30px;
    justify-content: space-evenly;
}
.tag {
    background: none;
    border: none;
    margin: 10px;
    font-weight: bold;
    font-size : 16px;
    cursor: pointer;

}
.tag:hover {
   transition: 0.20s;   
   cursor: pointer;
   text-decoration: underline;
}

@media only screen and (max-width:600px){
    .title-1-container {
        display: flex;
     }
     .title-1 {
         margin-left: 5px;
         margin-top: 10px;
         font-family: Lucida Console;
         color: #007466;
         letter-spacing: 1.1px;
         font-size: 14px;
     }
     .logo1 {
         margin-top: 5px;
         width: 40px;
         height: 20px;
         margin-left: 4px;
     }
     .logo2 {
         margin-top: 5px;
         width: 40px;
         height: 20px;
         margin-left: 4px;
     }z
     .title-2-container {
         display: flex;
     }
     .title-2 {
         font-family: Lucida Console;
         margin-left: 90px;
         font-size: 14px;
         margin-top: 2px;
         color: #bd8c00;
         letter-spacing: 1.1px;
     }
     
     .model-header-page {
         /* display: flex;
         flex-wrap: wrap;
         background-color: white;
         box-shadow: 2px 2px rgb(166, 166, 166);
         justify-content: space-between;
         position: fixed;
         box-sizing: border-box;
         top: 0px; */
         display: flex;
         flex-direction: column;
         background-color: white;
         box-shadow: 2px 2px rgb(166, 166, 166);
         justify-content: space-between;
         position: fixed;
         box-sizing: border-box;
         top: 0px;
     }
     .tags { 
         display: flex;
         margin: auto;
         align-items:end;
         padding: 4px;
         justify-content: space-evenly;
     }
     .tag {
         background: none;
         border: none;
         /* margin: 10px; */
         font-weight: bold;
         font-size : 8px;
         letter-spacing: 1.1px;
         cursor: pointer;
         text-decoration: underline;
         cursor: pointer;
         transition: background-color 0.3s ease, transform 0.2s ease;
     }

    
}

