.Search-Bar {
    margin-top: 180px;
    display: flex;
    justify-content: space-between;
    /* background-image: url('./Search-Bar-Logo.png');
    background-size: cover;
    background-position: center; */
    background-color: rgb(255, 255, 71);
}
.Search-Container {
    margin: auto;
}
.Search {
    height: 30px;
    width: 210px;
    border-radius: 20px 00px 0px 20px;
    text-align: center;
}
.Search-btn {
    height: 30px;
    background-color: #0f3806;
    color: white;
    font-weight: bold;
    border-radius: 0px 20px 20px 0px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}
.SelectBox {
    background-color: #0f3806;
    color: white;
    width: 200px;
    height: 30px;
    margin-left: 20px;
    align-self: center;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}
.offer-img {
    width: 75px;
    height: 75px;
    position: fixed;
    left: 3%;
    top: 85%;
    border-radius: 100%;
}

.cart-page {
    position: fixed;
    border: none;
    display: flex;
    align-items: center;
    right: 5%;
    top: 50%;
}

.cart-page img {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 80%;
}

.popup-container {
    top: 50%;
    left: 30%;
    width: 500px;
    height: 200px;
    border-radius: 30px;
    background-color: rgba(49, 105, 53, 0.624);
    align-content: center;
    position: fixed;
}
.Background-Container{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    background-image:url('./Background-Image.png');
    background-size: cover;
    background-position: center;
    padding-bottom: 20px;
    width: 100vw; /* Full width */
    height: 70vh;
}
.Gif-Container {
    display: flex;
    justify-content: space-between;
}

.background {
  margin-top: 20px;
}

.Gif-Diwali {
    margin-top: 20px;
    margin-left: 40px;
    margin-right: 40px;
    width: 130px;
    height: 130px;
}
.offer-tag {
   width: 100%;
   height: 500px;
}
.contact-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-weight: bold;
    margin-right: 20px;
}
.contact-1 {
    display: flex;
}
.contact-2 {
    display: flex;
}
.contact {
    margin-top: 20px;
    margin-right: 10px;
    width: 25px;
    height: 25px;
}
.number {
    margin-top: 20px;
}

.category-container {
    margin: auto;
    padding-top: 30px;
    width: 1050px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    border-radius: 30px;
    background-color: #ffffff;
    box-shadow:3px 4px rgba(0, 0, 0, 0.5)
}

.button-category {
    width: 170px;
    height: 170px;
    margin: 20px 20px 3px 20px;
    border: none;
    background:none;
}
.button-category :hover {
   width: 170px;
   height: 170px;
   transition: 0.29s;
}
.categoryImage {
    width: 150px;
    height: 150px;
    border-radius: 15px;
    box-shadow: 3px 4px rgba(0, 0, 0, 0.5)
}

.category-name-container {
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.horizontal-line {
    margin-left: 30px;
}

.footer-container {
    width: 100%;
    height: auto;
    background-color: rgb(255, 255, 71);
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 40px;
}
.footer-container h3 {
    font-style: italic;
    letter-spacing: 0.33px;
}
.footer-content-container {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}
.terms-condition {
    display: flex;
    flex-direction: column;
    text-align: start;
    padding: 2px;
}

.terms-condition h4 {
    text-decoration: underline;
}
.terms-condition p {
    margin: 3px;
    font-family: Georgia, 'Times New Roman', Times, serif;
}
.web-title {
    margin-bottom: 0.1px;
    color: rgb(0, 74, 84);
    font-family: 'Courier New', Courier, monospace;
    letter-spacing: 0.02px;
    font-size: 24px;
}
.welcome-content {
    width: 700px;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    font-size: 18px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(78, 78, 78);
    letter-spacing: 1px;
}
.content-container {
   display: flex;  
   margin-top: 15px;
   height: auto;
}
.content-inside {
    text-align: start;
    background-color: rgb(231, 231, 231);
    width: 500px;
    height: auto;
    margin-left: 70px;
    padding: 10px;
    border-radius: 20px;
}
.content-container p,li {
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    letter-spacing: 1.3px;
    color: #696969;
}
.content-container h4 {
    margin-left: 18px;
    text-decoration: underline;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 1px;
}
.webcontent-image-container {
    width: 600px;
    height: 950px;
    margin-top: 20px;
    margin-left: 12px;
    background-color: white;
    border-radius: 25px;
}
.box-design {
    width: 460px;
    margin-top: 130px;
    margin-left: 30px;
    height: 500px;
    background-color: #84f3b0;
}
.box-design img {
    width: 450px;
    margin-left: 70px;
    height: 450px;
    margin-top: -30px;
}

.product-content {
    display: flex;  
    justify-content: space-between;
}
.description-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 450px;
}
.description-content li {
    text-align: start;
}
.description-content p {
    font-size: 12px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: #696969;
    font-weight: bold;
}
.description-content h3 {
    text-align: start;
    margin-left: 20px;
    text-decoration: underline;
    margin-bottom: 1px;
}
.description-content-right {
    display: flex;
    flex-direction: column;
}
.description-content-right li {
    text-align: start;
    margin-right: 20px;
}
.description-content-right h3 {
    text-align: start;
    margin-right: 20px;
    text-decoration: underline;
    margin-bottom: 1px;
}
.description-content-right p {
    text-align: start;
    font-size: 12px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: #696969;
    font-weight: bold;
}
.developer {
    display: flex;
    justify-content: right;
    margin-right: 20px;
    font-size: 10px;
}
.cart-page {
    background: none;
    border: none;
    cursor: pointer;
}

.cart-page img {
    width: 100px;
    height: 100px;
    position: fixed;
    right: 5%;
    top: 50%;
    border-radius: 80%;
}


@media only screen and (max-width:600px){ 
    .Search-Bar {
        margin-top: 105px;
        display: flex;
        box-sizing: border-box;
        flex-wrap: wrap;
        background-color: rgb(255, 255, 71);
    }
    .Search-Container {
        width: 100px;
    }
    .Search {
        height: 20px;
        width: 70px;
        border-radius: 20px 00px 0px 20px;
        text-align: center;
        font-size: 8px;
    }
    .Search-btn {
        width: 30px;
        height: 20px;
        background-color: #0f3806;
        color: white;
        font-size: 8px;
        font-weight: bold;
        border-radius: 0px 20px 20px 0px;
        letter-spacing: 1.1px;
    }
    .SelectBox {
        background-color: #0f3806;
        color: white;
        width: 70px;
        height: 20px;
        margin-left: 10px;
        align-self: center;
        border-radius: 5px;
        padding: 2px;
        font-size: 10px;
    }
    
    .popup-container {
        top: 225px;
        left: 0%;
        width: 100%;
        height: 150px;
        border-radius: 15px;
        background-color: rgb(255, 255, 255);
        align-content: center;
        position: fixed;
        transition: 0.8s;
        box-shadow:3px 4px rgba(0, 0, 0, 0.5)
    }
    .popup-container input {
        width: 100px;
        font-size: 10px;
        background-color: white;
        border-radius: 10px 0px 0px 10px;
    }
    .popup-container button {
        width: 30px;
        font-size: 10px;
        text-align: center;
        background-color: rgb(0, 145, 255);
        color: rgb(255, 255, 255);
        border-radius: 0px 10px 10px 0px;
        
    }
    .Background-Container{
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        background-image:url('./Background-Image.png');
        background-size: cover;
        background-position: center;
        padding-bottom: 20px;
        /* width: 100%;  */
        box-sizing: border-box;
        height: 20vh;
    }
    .Gif-Container {
        display: flex;
        justify-content: space-between;
    }
    
    .background {
      margin-top: 20px;
    }
    
    .Gif-Diwali {
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
        width: 40px;
        height: 40px;
    }
    
    .contact-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-weight: bold;
        margin-right: 5px;
    }
    .contact-1 {
        display: flex;
    }
    .contact-2 {
        display: flex;
    }
    .contact {
        margin-top: 10px;
        margin-right: 2px;
        width: 10px;
        height: 10px;
    }
    .number {
        margin-top: 10px;
        font-size: 8px;
    }
    .Product-Mobile {
        font-size: 14px;
        text-decoration: underline;
        letter-spacing: 1.2px;
    }
    
    .category-container {
        margin: auto;
        padding-top: 30px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border-radius: 30px;
        background-color: #ffffff;
        /* box-shadow:3px 4px rgba(0, 0, 0, 0.5) */
    }
    
    .button-category {
        /* width: 80px;
        height: 80px;
        margin: 20px 20px 1px 20px;
        border: none;
        background:none; */

        width: 90px;
        height: 80px;
        margin: 20px 6px 1px 6px;
        border: none;
        background:none;
    }
    .button-category :hover {
       width: 100px;
       height: 100px;
       transition: 0.29s;
    }
    .categoryImage {
        width: 70px;
        height: 70px;
        border-radius: 15px;
        box-shadow: 3px 4px rgba(0, 0, 0, 0.5)
    }
    
    .category-name-container {
        text-align: center;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-size: 9px;
    }
    
    .horizontal-line {
        margin-left: 30px;
    }
    
    .footer-container {
        box-sizing: border-box;
        height: auto;
        background-color: rgb(255, 255, 71);
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 20px;
    }
    .footer-container h3 {
        font-style: italic;
        font-size: 8px;
        letter-spacing: 0.33px;
    }
    .footer-content-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-left: 4px;
        padding-right: 4px;
    }
    .terms-condition {
        display: flex;
        flex-direction: column;
        text-align: start;
        padding: 2px;
        font-size: 5px;
    }
    
    .terms-condition h4 {
        text-decoration: underline;
        font-size: 5px;
    }
    .terms-condition p {
        margin: 2px;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 5px;
    }
    .signature {
        font-size: 6px;
    }
    .final-thanks {
        font-size: 8px;
    }
    .web-title {
        margin-bottom: 0.1px;
        color: rgb(0, 89, 101);
        font-family: 'Courier New', Courier, monospace;
        letter-spacing: 0.02px;
        font-size: 8px;
        margin-top: 15px;
    }
    .welcome-content {
        width: 280px;
        display: flex;
        flex-wrap: wrap;
        margin: auto;
        font-size: 8px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        color: rgb(78, 78, 78);
        letter-spacing: 1px;
        margin-bottom: 10px;
    }
    .content-container {
       width: 100%;
       display: flex; 
       flex-direction: column; 
       flex-wrap: wrap;
       margin-top: 10px;
       height: auto;
       align-items: center;
       /* background-color: #0f3806; */
    }
    .content-inside {
        text-align: start;
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        background-color: rgb(255, 255, 255);
        width: 280px;
        height: auto;
        margin-left: 3%;
        /* padding: 5px; */
        border-radius: 20px;
    }
    .content-container p,li,hr {
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        letter-spacing: 1.1px;
        color: #696969;
        font-size: 6px;
        /* margin-left: -20px; */
    }
    .content-container h4 {
        /* margin-left: 10px; */
        text-decoration: underline;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        letter-spacing: 1px;
        font-size: 8px;
    }
    .webcontent-image-container {
        width: 250px;
        height: 250px;
        margin-top: 10px;
        background-color: white;
        border-radius: 25px;
    }
    .box-design {
        width: 230px;
        margin-top: 20px;
        margin-left: 5px;
        height: 230px;
        background-color: #84f3b0;
    }
    .box-design img {
        width: 230px;
        margin-left: 15px;
        height: 230px;
        margin-top: -10px;
    }
    
    .product-content {
        display: flex;  
        justify-content: space-between;
    }
    .description-content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .description-content li {
        text-align: start;
    }
    .description-content p {
        font-size: 6px;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        color: #696969;
        font-weight: bold;
        margin-top: -5px;
    }
    .description-content h3 {
        text-align: start;
        margin-left: 20px;
        text-decoration: underline;
        margin-bottom: -8px;
    }
    .description-content-right {
        display: flex;
        flex-direction: column;
    }
    .description-content-right li {
        text-align: start;
        margin-right: 10px;
    }
    .description-content-right h3 {
        text-align: start;
        margin-right: 20px;
        text-decoration: underline;
        margin-bottom: -8px;
    }
    .description-content-right p {
        text-align: start;
        font-size: 6px;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        color: #696969;
        font-weight: bold;
        margin-top: -5px;
    }
    .developer {
        display: flex;
        justify-content: right;
        margin-right: 10px;
    }
    .developer h3, a {
        font-size: 6px;
        color: black;
    }
    .loading-style {
        position: fixed;
        top: 40%;
        left: 42%;
        
    }
    .loading {
        width: 30px;
        height: 30px;
    }

    .offer-img {
        width: 70px;
        height: 70px;
        position: fixed;
        left: 5%;
        top: 55%;
        border-radius: 100%;
    }

    .cart-page {
        position: fixed;
        border: none;
        display: flex;
        align-items: center;
        right: 5%;
        top: 50%;
    }

    .cart-page img {
        position: relative;
        width: 65px;
        height: 65px;
        border-radius: 80%;
    }
       
    
}

