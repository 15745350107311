.order-container {
    margin: auto;
    padding-top: 30px;
    width: 1050px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    border-radius: 30px;
    background-color: #ffffff;
    box-shadow:3px 4px rgba(0, 0, 0, 0.5)
}
.background-order {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    background-image:url('./background-3.png');
    background-size: cover;
    background-position: top;
    padding-bottom: 20px;
    width: 100vw; /* Full width */
    height: 20vh;
}
.orders {
    width: 200px;
    height :auto;
    margin: 15px;
    border-radius: 20px;
    /* justify-content: center; */
    padding-bottom: 5px;
    background-color: rgb(255, 255, 255);
    box-shadow:3px 4px rgba(0, 0, 0, 0.5)
}

.orders h2 {
    margin-top: 2px;
    color: #107f78;
    letter-spacing: 1.2px;
    font-family: monospace;
}
.orders p {
    font-family: 'Times New Roman', Times, serif;
    color: #43b873;
    margin-top: 3px;
}
.order-image {
    width: 150px;
    height: 150px;
    border-radius: 20px;
}
.order-final-btn-container {
    display: flex;
    justify-content: flex-end;
}
.order-response-container button {
    margin-bottom: 5px;
    border: none;
    background: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}
.order-final-btn {
    width: 200px;
    height: 40px;
    background-color: #00cc6c;
    border: none;
    border-radius: 20px;
    color: white;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 20px;
    letter-spacing: 1.8px;
    margin-bottom: 15px;
    margin-top: 20px;
    margin-right: 140px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}
.form-container {
    top: 20%;
    left: 30%;
    width: 600px;
    height: autos;
    border-radius: 30px;
    background-color: #ffffff;
    justify-content: center;
    position: fixed;
    box-shadow:3px 4px rgba(0, 0, 0, 0.5)
}

.customer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 80px;
    /* align-items: center; */
    padding: 20px;
    
}
.forms {
    display: flex;
    padding: 15px;
}
.forms label {
    text-align: start;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #01352b;
}
.forms input {
    margin-left: 20px;
    border-top: none;
    border-left: none;
    border-right: none;
    background: none;
}
.submit-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    padding-bottom: 20px;
    
}
.submit-btn-container button {
    border-radius: 8px;
    width: 100px;
    background-color: #01352b;
    font-weight: bold;
    color: white;
    letter-spacing: 1.8px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}
.close-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    padding-top: 20px;
}
.close-btn-container img {
    width: 30px;
    height: 30px;
}
/* .close-btn-container button {
    border-radius: 8px;
    width: 100px;
    background-color: #640000;
    font-weight: bold;
    color: white;
    letter-spacing: 1.8px;
} */
.close-btn {
    border-radius: 8px;
    width: 100px;
    background-color: #640000;
    font-weight: bold;
    color: white;
    letter-spacing: 1.8px;
    margin-bottom: 20px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.order-response-container {
    top: 20%;
    left: 30%;
    width: 400px;
    height: 200px;
    border-style: solid;
    border: 0.5px;
    border: black;
    border-radius: 30px;
    background-color: #ffffff;
    justify-content: center;
    position: fixed;
    box-shadow:3px 4px rgba(0, 0, 0, 0.5)
}
.download-icon {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: none;
}

@media only screen and (max-width:600px){
    .background-order {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        background-image:url('./background-3.png');
        background-size: cover;
        background-position: top;
        padding-bottom: 20px;
        width: 100vw; /* Full width */
        height: 50px;
    }
    .order-container {
        margin: auto;
        padding-top: 10px;
        width:100%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        border-radius: 20px;
        background-color: #ffffff;
        box-shadow:3px 4px rgba(0, 0, 0, 0.5)
    }
    .orders {
        width: 100px;
        height :auto;
        margin: 3px;
        border-radius: 20px;
        /* justify-content: center; */
        padding-bottom: 5px;
        background-color: rgb(255, 255, 255);
        box-shadow:3px 4px rgba(0, 0, 0, 0.5);
    }
    .Order-Tittle {
        font-size: 12px;
        text-decoration: underline;
    }
    
    .orders h2 {
        margin-top: 2px;
        color: #107f78;
        letter-spacing: 1.2px;
        font-family: monospace;
        font-size: 10px;
    }
    .orders p {
        font-family: monospace;
        color: #00471e;
        margin-top: 3px;
        font-size: 8px;
    }
    .orders h4 {
        font-size: 8px;
    }
    .orders input label{
        font-size: 8px;
    }
    .orders label{
        font-size: 8px;
    }
    .order-image {
        width: 60px;
        height: 60px;
        border-radius: 15px;
    }
    .order-final-btn-container {
        display: flex;
        justify-content: flex-end;
    }
    .order-final-btn {
        width: 100px;
        height: 20px;
        background-color: #00cc6c;
        border: none;
        border-radius: 20px;
        color: white;
        font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        font-size: 8px;
        letter-spacing: 1.8px;
        margin-bottom: 15px;
        margin-top: 20px;
        margin-right: 40px;
        transition: background-color 0.3s ease, transform 0.2s ease;
    }
    .quantity-field {
        width: 20px;
        height: 10px;
        font-size: 6px;
        text-align: center;
        border-top: none;
        border-left: none;
        border-right: none;
    }
    .form-container {
        top: 30%;
        left: 0%;
        width: 100%;
        height: auto;
        border-radius: 30px;
        background-color: #ffffff;
        justify-content: center;
        position: fixed;
        box-shadow:3px 4px rgba(0, 0, 0, 0.5)
    }
    
    .customer-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 30px;
        align-items: center;
        padding: 10px;
        
    }
    .forms {
        display: flex;
        padding: 5px;
    }
    .forms label {
        text-align: start;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        color: #01352b;
        font-size: 10px;
    }
    .forms input {
        margin-left: 5px;
        border-top: none;
        border-left: none;
        border-right: none;
        background: none;
        font-size: 10px;
    }
    .submit-btn-container {
        display: flex;
        justify-content: flex-end;
        margin-right: 15px;
        padding-bottom: 10px;
        
    }
    .submit-btn-container button {
        border-radius: 8px;
        width: 60px;
        height: 20px;
        background-color: #01352b;
        font-weight: bold;
        color: white;
        font-size: 8px;
        letter-spacing: 1.8px;
        transition: background-color 0.3s ease, transform 0.2s ease;
    }
    .close-btn-container {
        display: flex;
        justify-content: flex-end;
        margin-right: 15px;
        padding-top: 10px;
    }
    .close-btn-container img {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background:none;
    }
    .close-btn-container {
        border: none;
        background: none;
    }
    
    .customer-response {
        font-size: 10px;
        margin-top: 20px;
    }
    .order-response {
        font-size: 10px;
    }
    .close-btn {
        border-radius: 8px;
        width: 40px;
        height: 12px;
        background-color: #640000;
        font-weight: bold;
        color: white;
        font-size: 6px;
        letter-spacing: 1.8px;
        margin-bottom: 10px;
        transition: background-color 0.3s ease, transform 0.2s ease;
    }
    .order-response-container {
        top: 50%;
        left: 0%;
        width: 100%;
        height: auto;
        border-radius: 30px;
        border: 0.5px solid rgb(65, 65, 65);
        background-color: #ffffff;
        justify-content: center;
        position: fixed;
        box-shadow:1.5px 3px rgba(0, 0, 0, 0.5)
    }
    .order-response-container button {
        font-size: 10px;
        margin-bottom: 5px;
        border: none;
        background: none;
        transition: background-color 0.3s ease, transform 0.2s ease;
        margin-bottom: 6px;
    }
    .download-icon {
        width: 5px;
        height: 5px;
        border: none;
        background-color: white;
        margin-bottom: 5px;
    }

    .loading-order-style {
        margin: auto;
        position: fixed;
        top: 40%;
        left: 40%;
    }
    .back-btn-order {
        display: flex;
        justify-content: flex-start;
        
    }
    .back-btn-order button {
        background-color: rgb(120, 110, 0);
        margin-left: 15px;
        margin-top: 10px;
        font-weight: bold;
        color: white;
        font-size: 10px;
        border: none;
        border-radius: 5px;
        width: 50px;
        transition: background-color 0.3s ease, transform 0.2s ease;
    }
    .quantity-response p {
      font-size: 6px;
      color: red;
    }

    .quantity-container {
        display: flex;
        justify-content: center;
    }
    .quantity-container button {
        background: none;
        border: none;
        color: #00b126;
        margin-left: 2px;
        margin-right: 2px;
        margin-bottom: 3px;
        font-weight: bold;
        font-size: 18px;
    }
    .cost-container {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 300px;
        height: 42px;
        border: black;
        border: 0.5px;
        border-style: solid;
        border-radius: 20px;
        justify-content: center;
        background-color:#ceffbc92;
    }
    .cost-title {
        font-size: 8px;
        margin: 1px;
        font-family: 'Times New Roman', Times, serif;
        letter-spacing: 1.1px;
        /* background-color: #00b126; */
        height: 25px;
        align-self: center;
    }
    .cost {
        display: flex;
        justify-content: space-evenly;
        /* background-color: #640000; */
        height: 15px;
        align-items: center;
    }
    .cost h4 {
        font-size: 8px;
        display: flex;
        font-weight: bold;
        letter-spacing: 1.1px;
    }
    .cost p {
        font-size: 8px;
        display: flex;
        font-weight: bold;
        letter-spacing: 1.1px;
    }
    .total-price {
        font-size: 8px;
        color: #ff0000;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        letter-spacing: 1px;
    }
    .saved-price {
        color: #007e88;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        letter-spacing: 1px;
    }
     
}