.banner {
    display: flex;
    justify-content: space-between;
    margin-top: 180px;
    height: 100px;
    width: 100%;
    background-image: url('../home-page/Search-Bar-Logo.png');
    background-size: cover;
    background-position: center;
}
.offer-tag {
    margin-left: 40px;
    width: 140px;
    height: 140px;
    border-radius: 100%;
    margin-top: 30px;
}
.sort-box {
    padding: 20px;
    margin-right: 20px;
    align-self: center;
}
.sort-box select {
    width: 200px;
    border-radius: 20px;
    text-align: center;
}
.banner label {
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: white;
    margin-right: 10px;
    font-size: 18px;
}
.product-container {
    margin: auto;
    padding-top: 30px;
    width: 1050px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    border-radius: 30px;
    background-color: #ffffff;
    box-shadow:3px 4px rgba(0, 0, 0, 0.5)
}
.products {
    width: 200px;
    height :auto;
    margin: 15px;
    border-radius: 20px;
    /* justify-content: center; */
    padding-bottom: 5px;
    background-color: rgb(255, 255, 255);
    box-shadow:3px 4px rgba(0, 0, 0, 0.5)
}
.products h2 {
    margin-top: 2px;
    color: #107f78;
    letter-spacing: 1.2px; 
    font-family: monospace;
}
.products p {
    font-family: 'Times New Roman', Times, serif;
    color: #43b873;
    margin-top: 3px;
}
.products button {
    width: 180px;
    border-radius: 10px;
    border: none;
    background-color: #a29a00;
    letter-spacing: 0.9px;
    font-weight: bold;
    color: #ffffff;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}
.price-tag {
    display: flex;
    margin-left: 40px;
    margin-top: 2px;
}

.orginal-price {
    margin-top: 10px;
    padding-left: 5px;
    font-weight: bold;
    font-style: italic;
    text-decoration: line-through;
    color: red;
}
/* .product-btn {
    width: 170px;
    height: 170px;
    border: none;
    background:none;
} */
/* .product-btn :hover {
    width: 180px;
    height: 180px;
    transition: 0.29s;
} */
.product-image {
    width: 150px;
    height: 150px;
    border-radius: 20px;
}
.order-btn-container {
    display: flex;
    justify-content: flex-end;
}
.order-btn {
    width: 200px;
    height: 40px;
    background-color: #00cc6c;
    border: none;
    border-radius: 20px;
    color: white;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 20px;
    letter-spacing: 1.8px;
    margin-bottom: 15px;
    margin-top: 20px;
    margin-right: 140px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

@media only screen and (max-width:600px){
    .banner {
        display: flex;
        justify-content: space-between;
        margin-top: 90px;
        height: 50px;
        /* width: 100%; */
        box-sizing: border-box;
        background-image: url('../home-page/Search-Bar-Logo.png');
        background-size: cover;
        background-position: center;
    }
    .offer-tag {
        margin-left: 10px;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        margin-top: 30px;
    }
    .sort-box {
        padding: 10px;
        margin-right: 10px;
        align-self: center;
    }
    .sort-box select {
        width: 60px;
        border-radius: 10px;
        text-align: center;
        font-size: 6px;
    }
    .banner label {
        font-weight: bold;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        color: white;
        margin-right: 5px;
        font-size: 8px;
    }
    .product-heading {
        font-size: 12px;
        letter-spacing: 1.1px;
    }
    .product-container {
        margin: auto;
        padding-top: 30px;
        width:100%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        border-radius: 30px;
        background-color: #ffffff;
        box-shadow:3px 4px rgba(0, 0, 0, 0.5)
    }
    .products {
        width: 100px;
        height :auto;
        margin: 3px;
        border-radius: 20px;
        /* justify-content: center; */
        padding-bottom: 5px;
        background-color: rgb(255, 255, 255);
        box-shadow:3px 4px rgba(0, 0, 0, 0.5)
    }
    .navigate-tag {
        margin-top: 25px;
        display: flex;
        justify-content: flex-start;
        margin-left: 20px;
    }
    .navigate-tag button {
        border: none;
        background: none;
        font-size: 12px;
        color: #008e3b;
        font-weight: bold;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        text-decoration: underline;
    }
    .navigate-tag h3 {
        font-size: 12px;
        color: #008e3b;
        font-weight: bold;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }
    .products h2 {
        margin-top: 2px;
        color: #107f78;
        letter-spacing: 1.2px; 
        font-family: monospace;
        font-size: 8px;
        font-weight: bolder;
    }
    .products p {
        font-family:monospace;
        color: #008e3b;
        margin-top: 3px;
        font-size: 8px;
    }
    .products button {
        width: 85px;
        height: 15px;
        border-radius: 10px;
        border: none;
        background-color: #a29a00;
        letter-spacing: 0.9px;
        font-weight: bold;
        font-size: 6px;
        color: #ffffff;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.2s ease;
    }
    .price-tag {
        display: flex;
        margin-left: 20px;
        margin-top: 2px;
        font-size: 8px;
    }
    
    .orginal-price {
        margin-top: 10px;
        padding-left: 5px;
        font-weight: bold;
        font-style: italic;
        text-decoration: line-through;
        color: red;
        font-size: 8px;
    }
    .product-image {
        width: 60px;
        height: 60px;
        border-radius: 15px;
        box-shadow: 3px 4px rgba(0, 0, 0, 0.5)
    }
    .order-btn-container {
        display: flex;
        justify-content: flex-end;
    }
    .order-btn {
        width: 100px;
        height: 20px;
        background-color: #00cc6c;
        border: none;
        border-radius: 20px;
        color: white;
        font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        font-size: 8px;
        letter-spacing: 1.8px;
        margin-bottom: 15px;
        margin-top: 20px;
        margin-right: 40px;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.2s ease;
    }

    .footer-container {
        /* width: 100%; */
        box-sizing: border-box;
        height: auto;
        background-color: rgb(255, 255, 71);
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 20px;
    }
    .footer-container h3 {
        font-style: italic;
        font-size: 8px;
        letter-spacing: 0.33px;
    }
    .footer-content-container {
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;
    }
    .terms-condition {
        display: flex;
        flex-direction: column;
        text-align: start;
        padding: 2px;
        font-size: 5px;
    }
    
    .terms-condition h4 {
        text-decoration: underline;
        font-size: 5px;
    }
    .terms-condition p {
        margin: 3px;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 5px;
    }
    .signature {
        font-size: 6px;
    }
    .final-thanks {
        font-size: 8px;
    }
    .loading-cart-style {
        margin: auto;
        position: fixed;
        top: 40%;
        left: 40%;
    }
}