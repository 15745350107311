@media only screen and (max-width:600px){
    .history-container {
        margin: auto;
        padding-top: 5px;
        width:100%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        border-radius: 20px;
        background-color: #ffffff;
        box-shadow:3px 4px rgba(0, 0, 0, 0.5)
    }

    .history {
        width: 130px;
        height :auto;
        margin: 5px;
        border-radius: 20px;
        /* justify-content: center; */
        padding-bottom: 5px;
        background-color: rgb(255, 255, 255);
        box-shadow:3px 4px rgba(0, 0, 0, 0.5)
    }

    .history h3 {
        font-size: 8px;
        margin: 10px;
        color: #006357;
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        letter-spacing: 0.8px;
    }
    .history-heading {
        font-size: 12px;
        text-decoration: underline;
    }

    .order-background {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        background-image:url('../order-page/background-3.png');
        background-size: cover;
        background-position: top;
        padding-bottom: 20px;
        width: 100vw; /* Full width */
        height: 50px;
    }
    .history-btn {
        width: 100px;
        height: 18px;
        background-color: #00a1ae;
        border-radius: 10px;
        border: none;
        font-size: 8px;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 1.3px;
    }
    .back-btn {
        margin-top: 10px;
        margin-left: 10px;
        background-color: rgb(82, 82, 0);
        font-size: 10px;
        border-radius: 5px;
        border: none;
        color: white;
        font-weight: bold;
    }
}